import { lazyRouteComponent } from "~/utilities/vite/lazyRouteComponent";
import { registry } from "~/utilities/vite/reactIslandRegistry";
import "./shared";

registry.registerIsland({
  name: "jobber/features/Marketing/ClientReferrals/EntryPoint",
  Component: lazyRouteComponent(
    () => import("~/jobber/features/Marketing/ClientReferrals/EntryPoint"),
    "EntryPoint",
  ),
});

registry.registerIsland({
  name: "jobber/settings/teamOrganization/manageTeam",
  Component: lazyRouteComponent(
    () => import("~/jobber/settings/teamOrganization/manageTeam"),
    "ManageTeamRoute",
  ),
});

registry.registerIsland({
  name: "jobber/settings/teamOrganization/aiReceptionist",
  Component: lazyRouteComponent(
    () => import("~/jobber/settings/teamOrganization/aiReceptionist"),
    "AiReceptionist",
  ),
});

registry.registerIsland({
  name: "jobber/appPlatform/components/AccessToken",
  Component: lazyRouteComponent(
    () => import("~/jobber/appPlatform/components/AccessToken"),
    "AccessToken",
  ),
});

registry.registerIsland({
  name: "jobber/settings/companySchedule/CompanyScheduleSettingsCard/components/CompanyScheduleWeekDisplayData",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/settings/companySchedule/CompanyScheduleSettingsCard/components/CompanyScheduleWeekDisplayData"
      ),
    "CompanyScheduleWeekDisplayData",
  ),
});

registry.registerIsland({
  name: "jobber/settings/companySchedule/CompanyScheduleSettingsData",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/settings/companySchedule/CompanyScheduleSettingsData"),
    "CompanyScheduleSettingsData",
  ),
});

registry.registerIsland({
  name: "jobber/schedule/Schedule",
  Component: lazyRouteComponent(
    () => import("~/jobber/schedule/Schedule"),
    "Schedule",
  ),
});

registry.registerIsland({
  name: "jobber/schedule/settings/ScheduleAvailabilitySettings",
  Component: lazyRouteComponent(
    () => import("~/jobber/schedule/settings/ScheduleAvailabilitySettings"),
    "ScheduleAvailabilitySettings",
  ),
});

registry.registerIsland({
  name: "jobber/activityFeed/components/ActivityFeed",
  Component: lazyRouteComponent(
    () => import("~/jobber/activityFeed/components/ActivityFeed"),
    "ActivityFeed",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/views/QuoteTotalComponents",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/quotes/views/QuoteTotalComponents/QuoteTotalComponents"),
    "QuoteTotalComponents",
  ),
});

registry.registerIsland({
  name: "jobber/wisetack/components/ApprovedAmountRow",
  Component: lazyRouteComponent(
    () => import("~/jobber/wisetack/components/ApprovedAmountRow"),
    "ApprovedAmountRow",
  ),
});

registry.registerIsland({
  name: "jobber/wisetack/components/WisetackSettings",
  Component: lazyRouteComponent(
    () => import("~/jobber/wisetack/components/WisetackSettings"),
    "WisetackSettings",
  ),
});

registry.registerIsland({
  name: "jobber/wisetack/WisetackSetup",
  Component: lazyRouteComponent(
    () => import("~/jobber/wisetack/WisetackSetup"),
    "WisetackSetup",
  ),
});

registry.registerIsland({
  name: "jobber/actionBar/components/ActionBar",
  Component: lazyRouteComponent(
    () => import("~/jobber/actionBar/components/ActionBar"),
    "ActionBar",
  ),
});

registry.registerIsland({
  name: "jobber/actionBar/components/CtaPopoverActionBar",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/actionBar/components/CtaPopoverActionBar/CtaPopoverActionBar"
      ),
    "CtaPopoverActionBar",
  ),
});

registry.registerIsland({
  name: "jobber/usageBasedFreeAccess/MonthlyLimitModal",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/usageBasedFreeAccess/MonthlyLimitModal/MonthlyLimitModal"
      ),
    "MonthlyLimitModal",
  ),
});

registry.registerIsland({
  name: "jobber/clients/clientImport/ClientImportButton",
  Component: lazyRouteComponent(
    () => import("~/jobber/clients/clientImport"),
    "ClientImportButton",
  ),
});

registry.registerIsland({
  name: "jobber/fleetsharpPositionSubscriber",
  Component: lazyRouteComponent(
    () => import("~/jobber/fleetsharpPositionSubscriber"),
    "FleetsharpPositionSubscriber",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/view/QuoteEditor",
  Component: lazyRouteComponent(
    () => import("~/jobber/quotes/views/QuoteEditor"),
    "QuoteEditor",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/view/QuoteEditor/components/QuoteDetails",
  Component: lazyRouteComponent(
    () => import("~/jobber/quotes/views/QuoteEditor/components/QuoteDetails"),
    "QuoteDetails",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/view/QuoteEditor/components/ClientViewOptions",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/quotes/views/QuoteEditor/components/ClientViewOptions"),
    "ClientViewOptions",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/view/QuoteEditor/components/ClientMessages",
  Component: lazyRouteComponent(
    () => import("~/jobber/quotes/views/QuoteEditor/components/ClientMessages"),
    "ClientMessages",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/views/QuoteRouter",
  Component: lazyRouteComponent(
    () => import("~/jobber/quotes/views/QuoteRouter"),
    "QuoteRouter",
  ),
  // We need to disable React.StrictMode for this island because it uses
  // react-beautiful-dnd which is not compatible with React.StrictMode.
  // The library is no longer supported and we are considering alternatives.
  // See https://jobber.atlassian.net/browse/JOB-91988
  // and https://github.com/atlassian/react-beautiful-dnd
  // for more context.
  unsafe__disableReactStrictMode: true,
});

registry.registerIsland({
  name: "jobber/quotes/views/QuotesIndex",
  Component: lazyRouteComponent(
    () => import("~/jobber/quotes/views/QuotesIndex"),
    "QuotesIndex",
  ),
});

registry.registerIsland({
  name: "jobber/videoExplainerCard/VideoExplainerCard",
  Component: lazyRouteComponent(
    () => import("~/jobber/videoExplainerCard"),
    "VideoExplainerCard",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/views/QuotesMarkupCTA",
  Component: lazyRouteComponent(
    () => import("~/jobber/quotes/views/QuotesMarkupCTA"),
    "QuotesMarkupCTA",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/views/FirstQuotePrefillModal/FirstQuotePrefillModal",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/quotes/views/FirstQuotePrefillModal/FirstQuotePrefillModal"
      ),
    "FirstQuotePrefillModal",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/views/QuoteUpgradeModalCTA",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/quotes/views/QuoteUpgradeModalCTA/QuoteUpgradeModalCTA"),
    "QuoteUpgradeModalCTA",
  ),
});

registry.registerIsland({
  name: "jobber/signup/components/DivertMessage",
  Component: lazyRouteComponent(
    () => import("~/jobber/signup/components/DivertMessage"),
    "DivertMessage",
  ),
});

registry.registerIsland({
  name: "jobber/automations/automationsSideDrawer/AutomationsSideDrawerRailsWrapper",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/automations/components/automationsSideDrawer/AutomationsSideDrawer"
      ),
    "AutomationsSideDrawer",
  ),
});

registry.registerIsland({
  name: "jobber/settings/automations",
  Component: lazyRouteComponent(
    () => import("~/jobber/settings/automations/AutomationsSettingsRoute"),
    "AutomationsSettingsRoute",
  ),
});

registry.registerIsland({
  name: "jobber/jPayAutoenable/components/JPayAutoenableReminderModal",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/jPayAutoenable/components/JPayAutoenableReminderModal"),
    "JPayAutoenableReminderModal",
  ),
});

registry.registerIsland({
  name: "jobber/jPayAutoenable/components/JPayAutoenableEducationModal",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/jPayAutoenable/components/JPayAutoenableEducationModal"),
    "JPayAutoenableEducationModal",
  ),
});

registry.registerIsland({
  name: "jobber/invoices/components/JPaySetupModal",
  Component: lazyRouteComponent(
    () => import("~/jobber/invoices/components/JPaySetupModal"),
    "JPaySetupModal",
  ),
});

registry.registerIsland({
  name: "jobber/workItems/views/WorkItemsPage/WorkItemsPageWrapper",
  Component: lazyRouteComponent(
    () => import("~/jobber/workItems/views/WorkItemsPage"),
    "WorkItemsPageWrapper",
  ),
});

registry.registerIsland({
  name: "jobber/calendar/fleetsharpMarketingModal",
  Component: lazyRouteComponent(
    () => import("~/jobber/calendar/fleetsharpMarketingModal"),
    "FleetsharpMarketingModal",
  ),
});

registry.registerIsland({
  name: "jobber/calendar/jobsCalendar/JobsCalendar",
  Component: lazyRouteComponent(
    () => import("~/jobber/calendar/jobsCalendar/JobsCalendar"),
    "JobsCalendar",
  ),
});

registry.registerIsland({
  name: "jobber/calendar/vehicle/VehicleEdit",
  Component: lazyRouteComponent(
    () => import("~/jobber/calendar/vehicle/VehicleEdit"),
    "VehicleEdit",
  ),
});

registry.registerIsland({
  name: "jobber/settings/requestsSettings/ScRequestUploadCard",
  Component: lazyRouteComponent(
    () => import("~/jobber/settings/requestsSettings/ScRequestUploadCard"),
    "ScRequestUploadCard",
  ),
});

registry.registerIsland({
  name: "jobber/settings/delegateReservation/DelegateReservation",
  Component: lazyRouteComponent(
    () => import("~/jobber/settings/delegateReservation"),
    "DelegateReservation",
  ),
});

registry.registerIsland({
  name: "jobber/marketingCampaignsExpansion/LandingPage",
  Component: lazyRouteComponent(
    () => import("~/jobber/marketingCampaignsExpansion/LandingPage"),
    "MarketingCampaignsExpansionLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/mapLegend/components/MapLegend",
  Component: lazyRouteComponent(
    () => import("~/jobber/mapLegend/components/MapLegend"),
    "MapLegend",
  ),
});

registry.registerIsland({
  name: "jobber/payments_react/JobberPaymentsTipsCTA",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/payments_react/JobberPaymentsTipsCTA/JobberPaymentsTipsCTA"
      ),
    "JobberPaymentsTipsCTA",
  ),
});

registry.registerIsland({
  name: "jobber/features/Reporting/components/ReportBetaTag/ReportPageLink",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/features/Reporting/components/ReportBetaTag/ReportPageLink"
      ),
    "ReportPageLink",
  ),
});

registry.registerIsland({
  name: "jobber/features/Reporting/components/Disclosure/Disclosure",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/features/Reporting/components/Disclosure/Disclosure"),
    "Disclosure",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/IntercomChatButton/IntercomChatButton",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/billing/components/IntercomChatButton/IntercomChatButton"
      ),
    "IntercomChatButton",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/IncreaseUserLimitCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/billing/components/IncreaseUserLimitCard/IncreaseUserLimitCard"
      ),
    "IncreaseUserLimitCard",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/IncreaseUserLimitCard/CardContent",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/billing/components/IncreaseUserLimitCard/CardContent/CardContent"
      ),
    "CardContent",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/Payments/Payments",
  Component: lazyRouteComponent(
    () => import("~/jobber/billing/components/Payments/Payments"),
    "Payments",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/SubscriptionCancellationCard/SubscriptionCancellationCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/billing/components/SubscriptionCancellationCard/SubscriptionCancellationCard"
      ),
    "SubscriptionCancellationCard",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/SubscriptionReactivationCard/SubscriptionReactivationCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/billing/components/SubscriptionReactivationCard/SubscriptionReactivationCard"
      ),
    "SubscriptionReactivationCard",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/UpdateResult/UpdateResult",
  Component: lazyRouteComponent(
    () => import("~/jobber/billing/components/UpdateResult/UpdateResult"),
    "UpdateResult",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/CheckoutLink/DropdownItem",
  Component: lazyRouteComponent(
    () => import("~/jobber/billing/components/CheckoutLink/CheckoutLink"),
    "DropdownItem",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/CheckoutLink/PrimaryActionButton",
  Component: lazyRouteComponent(
    () => import("~/jobber/billing/components/CheckoutLink/CheckoutLink"),
    "PrimaryActionButton",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/LicenseManagementCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/billing/components/LicenseManagementCard/LicenseManagementCard"
      ),
    "LicenseManagementCard",
  ),
});

registry.registerIsland({
  name: "jobber/features/Clients/views/ClientsIndex.loader",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/features/Clients/views/ClientsIndex/ClientsIndex.loader"
      ),
    "ClientsIndexLoader",
  ),
});

registry.registerIsland({
  name: "jobber/managed_accounts/InlineSignupCTA",
  Component: lazyRouteComponent(
    () => import("~/jobber/managed_accounts/InlineSignupCTA/InlineSignupCTA"),
    "InlineSignupCTA",
  ),
});

registry.registerIsland({
  name: "jobber/features/Reporting/ReportingRoute",
  Component: lazyRouteComponent(
    () => import("~/jobber/features/Reporting/ReportingRoute"),
    "ReportingRoute",
  ),
});

registry.registerIsland({
  name: "jobber/customFields/components/ExpansionCustomFieldsUpgradeCard/ExpansionCustomFieldsUpgradeCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/customFields/components/ExpansionCustomFieldsUpgradeCard/ExpansionCustomFieldsUpgradeCard"
      ),
    "ExpansionCustomFieldsUpgradeCard",
  ),
});

registry.registerIsland({
  name: "jobber/franchise/features/LeadRouting",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/franchise/features/LeadRouting/LeadRoutingSettingsRoute"
      ),
    "LeadRoutingSettingsRoute",
  ),
});

registry.registerIsland({
  name: "jobber/upgrade/components/Onboarding/ExperienceModal",
  Component: lazyRouteComponent(
    () => import("~/jobber/upgrade/components/Onboarding/ExperienceModal"),
    "ExperienceModal",
  ),
});

registry.registerIsland({
  name: "jobber/connectToGrowTrial/components/ConnectToGrowEndOfTrialModal",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/connectToGrowTrial/components/ConnectToGrowEndOfTrialModal/ConnectToGrowEndOfTrialModal"
      ),
    "ConnectToGrowEndOfTrialModal",
  ),
});

registry.registerIsland({
  name: "jobber/jobs/views/JobsIndex",
  Component: lazyRouteComponent(
    () => import("~/jobber/jobs/views/JobsIndex"),
    "JobsIndex",
  ),
});

registry.registerIsland({
  name: "jobber/landingPages/pages/jobsLandingPage/JobsLandingPage",
  Component: lazyRouteComponent(
    () => import("~/jobber/landingPages/pages/jobsLandingPage/JobsLandingPage"),
    "JobsLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/landingPages/pages/jobCostingLandingPage/JobCostingLandingPage",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/landingPages/pages/jobCostingLandingPage/JobCostingLandingPage"
      ),
    "JobCostingLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/landingPages/pages/jobFormsLandingPage/JobFormsLandingPage",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/landingPages/pages/jobFormsLandingPage/JobFormsLandingPage"
      ),
    "JobFormsLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/landingPages/pages/expenseTrackingLandingPage/ExpenseTrackingLandingPage",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/landingPages/pages/expenseTrackingLandingPage/ExpenseTrackingLandingPage"
      ),
    "ExpenseTrackingLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/landingPages/pages/onlineBookingLandingPage/OnlineBookingLandingPage",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/landingPages/pages/onlineBookingLandingPage/OnlineBookingLandingPage"
      ),
    "OnlineBookingLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/landingPages/pages/routeOptimizationLandingPage/RouteOptimizationLandingPage",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/landingPages/pages/routeOptimizationLandingPage/RouteOptimizationLandingPage"
      ),
    "RouteOptimizationLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/landingPages/pages/timesheetsLandingPage/TimesheetsLandingPage",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/landingPages/pages/timesheetsLandingPage/TimesheetsLandingPage"
      ),
    "TimesheetsLandingPage",
  ),
});
registry.registerIsland({
  name: "bunker/imageUpload",
  Component: lazyRouteComponent(
    () => import("~/bunker/imageUpload"),
    "ImageUpload",
  ),
});

registry.registerIsland({
  name: "jobber/features/Jobs/views/JobInsights.loader",
  Component: lazyRouteComponent(
    () => import("~/jobber/features/Jobs/views/JobInsights/JobInsights.loader"),
    "JobInsightsLoader",
  ),
});

registry.registerIsland({
  name: "jobber/workOrders/components/SubcontractorAssignmentCard.loader",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/workOrders/components/SubcontractorAssignmentCard/SubcontractorAssignmentCard.loader"
      ),
    "SubcontractorAssignmentCardLoader",
  ),
});

registry.registerIsland({
  name: "jobber/insightWidgets",
  Component: lazyRouteComponent(
    () => import("~/jobber/insightWidgets/InsightWidgetsLoader"),
    "InsightWidgetsLoader",
  ),
});

registry.registerIsland({
  name: "jobber/invoices/components/ReviewsDiscoveryBanner/ReviewsDiscoveryBanner",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/invoices/components/ReviewsDiscoveryBanner/ReviewsDiscoveryBanner"
      ),
    "ReviewsDiscoveryBanner",
  ),
});

registry.registerIsland({
  name: "jobber/dashboard/components/FirstLoginLoadingOverlay",
  Component: lazyRouteComponent(
    () => import("jobber/dashboard/components/FirstLoginLoadingOverlay"),
    "FirstLoginLoadingOverlay",
  ),
});

registry.registerIsland({
  name: "jobber/experimental/HappyPathP1V2/LandingPage",
  Component: lazyRouteComponent(
    () => import("jobber/experimental/HappyPathP1V2/LandingPage"),
    "LandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/workOrders/components/AssignedSubcontractorsCard/AssignedSubcontractorsCard.loader",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/workOrders/components/AssignedSubcontractorsCard/AssignedSubcontractorsCard.loader"
      ),
    "AssignedSubcontractorsCardLoader",
  ),
});

registry.registerIsland({
  name: "jobber/SubcontractorSignupForm/SubcontractorSignupForm",
  Component: lazyRouteComponent(
    () => import("~/jobber/SubcontractorSignupForm/SubcontractorSignupForm"),
    "SubcontractorSignupForm",
  ),
});

registry.registerIsland({
  name: "jobber/clients/components/ReviewsDiscoveryCard/ReviewsDiscoveryCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/clients/components/ReviewsDiscoveryCard/ReviewsDiscoveryCard"
      ),
    "ReviewsDiscoveryCard",
  ),
});

registry.registerIsland({
  name: "jobber/features/Home/JobberDesktopPromotion/JobberDesktopPromotion",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/features/Home/JobberDesktopPromotion/JobberDesktopPromotion"
      ),
    "JobberDesktopPromotion",
  ),
});

registry.registerIsland({
  name: "jobber/JPayEnablement/components/JobJPayEnablementCard/JobJPayEnablementCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/JPayEnablement/components/JobJPayEnablementCard/JobJPayEnablementCard"
      ),
    "JobJPayEnablementCard",
  ),
});

registry.registerIsland({
  name: "jobber/website/Website",
  Component: lazyRouteComponent(
    () => import("~/jobber/website/Website.loader"),
    "WebsiteLoader",
  ),
});

registry.registerIsland({
  name: "jobber/JPayRefunds/IssueRefund",
  Component: lazyRouteComponent(
    () => import("~/jobber/JPayRefunds/IssueRefund.loader"),
    "IssueRefundLoader",
  ),
});

registry.registerIsland({
  name: "jobber/JPayEnablement/components/RecurringJobJPayEnablementCard/RecurringJobJPayEnablementCard.loader",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/JPayEnablement/components/RecurringJobJPayEnablementCard/RecurringJobJPayEnablementCard.loader"
      ),
    "RecurringJobJPayEnablementCardLoader",
  ),
});

registry.registerIsland({
  name: "jobber/JPayEnablement/components/QuoteJPayEnablementCard/QuoteJPayEnablementCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/JPayEnablement/components/QuoteJPayEnablementCard/QuoteJPayEnablementCard"
      ),
    "QuoteJPayEnablementCard",
  ),
});

registry.registerIsland({
  name: "jobber/JPayEnablement/components/InvoiceJPayEnablementCard/InvoiceJPayEnablementCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/JPayEnablement/components/InvoiceJPayEnablementCard/InvoiceJPayEnablementCard"
      ),
    "InvoiceJPayEnablementCard",
  ),
});

registry.registerIsland({
  name: "jobber/invoices/views/InvoicesIndexPage",
  Component: lazyRouteComponent(
    () => import("~/jobber/invoices/views/InvoicesIndexPage"),
    "InvoicesIndexPage",
  ),
});

registry.registerIsland({
  name: "jobber/jobs/views/JobsImportButton",
  Component: lazyRouteComponent(
    () => import("~/jobber/jobs/views/JobsImportButton"),
    "JobsImportButton",
  ),
});

registry.registerIsland({
  name: "jobber/jobs/views/JobsImport/JobsImportModal/JobsImportModalContent",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/jobs/views/JobsImport/JobsImportModal/JobsImportModalContent"
      ),
    "JobsImportModalContent",
  ),
});

registry.registerIsland({
  name: "jobber/jobs/views/JobsImport/JobsImportModal/JobsImportFlatFileModalContent",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/jobs/views/JobsImport/JobsImportModal/JobsImportFlatFileModalContent"
      ),
    "JobsImportFlatFileModalContent",
  ),
});

registry.registerIsland({
  name: "jobber/jobs/views/JobsImport/JobsImportModal/JobsImportGoogleCalendarModalContent",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/jobs/views/JobsImport/JobsImportModal/JobsImportGoogleCalendarModalContent"
      ),
    "JobsImportGoogleCalendarModalContent",
  ),
});

registry.registerIsland({
  name: "jobber/linkedNotes/LinkedNotes",
  Component: lazyRouteComponent(
    () => import("~/jobber/linkedNotes/LinkedNotes"),
    "LinkedNotes",
  ),
});

registry.registerIsland({
  name: "jobber/settings/darkModeToggle",
  Component: lazyRouteComponent(
    () => import("~/jobber/settings/darkModeToggle/DarkModeToggle"),
    "DarkModeToggle",
  ),
});

registry.registerIsland({
  name: "jobber/workOrders/components/DepositDetails/DepositDetailsWrapper",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/workOrders/components/DepositDetails/DepositDetailsWrapper"
      ),
    "DepositDetailsWrapper",
  ),
});

registry.registerIsland({
  name: "jobber/workOrders/components/JobBalancesTable.loader",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/workOrders/components/JobBalancesTable/JobBalancesTable.loader"
      ),
    "JobBalancesTableLoader",
  ),
});

registry.registerIsland({
  name: "jobber/settings/requestSettings/googleAnalyticsSettingsCard/GoogleAnalyticsSettingsCard",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/settings/requestsSettings/googleAnalyticsSettingsCard/GoogleAnalyticsSettingsCard"
      ),
    "GoogleAnalyticsSettingsCard",
  ),
});

registry.registerIsland({
  name: "jobber/features/Marketing/DashboardRoute",
  Component: lazyRouteComponent(
    () => import("~/jobber/features/Marketing/DashboardRoute"),
    "DashboardRoute",
  ),
});

registry.registerIsland({
  name: "jobber/features/Payments/UpcomingRequirementsDueModal/UpcomingRequirementsDueModal",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/features/Payments/UpcomingRequirementsDueModal/UpcomingRequirementsDueModal"
      ),
    "UpcomingRequirementsDueModal",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/QuoteLayout/QuoteLayoutDisabledBanner",
  Component: lazyRouteComponent(() => {
    return import(
      "~/jobber/quotes/components/QuoteLayout/components/QuoteLayoutDisabledBanner/QuoteLayoutDisabledBanner"
    );
  }, "QuoteLayoutDisabledBanner"),
});

registry.registerIsland({
  name: "jobber/quotes/components/QuoteLayout/components/QuoteModeToggle",
  Component: lazyRouteComponent(() => {
    return import(
      "~/jobber/quotes/components/QuoteLayout/components/QuoteModeToggle/QuoteModeToggle"
    );
  }, "QuoteModeToggleWrapped"),
});

registry.registerIsland({
  name: "jobber/quotes/components/QuoteCreateModal/QuoteCreateModalWrapped",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/quotes/components/QuoteCreateModal/QuoteCreateModalWrapped"
      ),
    "QuoteCreateModalWrapped",
  ),
});

registry.registerIsland({
  name: "jobber/quotes/QuoteLayout/FormSlot",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/quotes/components/QuoteLayout/components/FormSlotWrapper/FormSlotWrapper"
      ),
    "FormSlotWrapper",
  ),
});

registry.registerIsland({
  name: "jobber/plans/PlansPage",
  Component: lazyRouteComponent(
    () => import("~/jobber/plans/PlansPage"),
    "PlansPage",
  ),
});

registry.registerIsland({
  name: "jobber/clients/leadAttribution/ClientLeadSourceApp",
  Component: lazyRouteComponent(
    () => import("~/jobber/clients/leadAttribution/ClientLeadSourceApp"),
    "ClientLeadSourceApp",
  ),
});

registry.registerIsland({
  name: "jobber/clients/leadAttribution/LeadSourceContainer",
  Component: lazyRouteComponent(
    () => import("~/jobber/clients/leadAttribution/LeadSourceContainer"),
    "LeadSourceContainer",
  ),
});

registry.registerIsland({
  name: "jobber/clients/leadAttribution/ChipClientCustomLeadSourceSelect",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/clients/leadAttribution/ChipClientCustomLeadSourceSelect"
      ),
    "ChipClientCustomLeadSourceSelect",
  ),
});

registry.registerIsland({
  name: "jobber/workOrders/components/InvoiceGeneratorSubmitButton",
  Component: lazyRouteComponent(
    () => import("~/jobber/workOrders/components/InvoiceGeneratorSubmitButton"),
    "InvoiceGeneratorSubmitButton",
  ),
});

registry.registerIsland({
  name: "jobber/invoices/components/DuePercentage",
  Component: lazyRouteComponent(
    () => import("~/jobber/invoices/components/DuePercentage"),
    "DuePercentage",
  ),
});

registry.registerIsland({
  name: "jobber/marketingSuiteExpansion/LandingPage",
  Component: lazyRouteComponent(
    () =>
      import("~/jobber/marketingSuiteExpansion/LandingPage/LandingPage.loader"),
    "MarketingSuiteExpansionLandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/invoices/components/ProgressInvoicingSubTotalPercentage",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/invoices/components/ProgressInvoicingSubTotalPercentage"
      ),
    "ProgressInvoicingSubTotalPercentage",
  ),
});

registry.registerIsland({
  name: "jobber/invoices/components/AddLineItemWarning",
  Component: lazyRouteComponent(
    () => import("~/jobber/invoices/components/AddLineItemWarning"),
    "AddLineItemWarning",
  ),
});

registry.registerIsland({
  name: "jobber/workOrders/components/InvoiceTracker",
  Component: lazyRouteComponent(
    () => import("~/jobber/workOrders/components/InvoiceTracker"),
    "InvoiceTracker",
  ),
});

registry.registerIsland({
  name: "jobber/invoices/components/JobDetailsSection",
  Component: lazyRouteComponent(
    () => import("~/jobber/invoices/components/JobDetailsSection"),
    "JobDetailsSection",
  ),
});

registry.registerIsland({
  name: "jobber/secureDocuments/UploadPage",
  Component: lazyRouteComponent(
    () => import("~/jobber/secureDocuments/views/UploadPage"),
    "UploadPage",
  ),
});

registry.registerIsland({
  name: "jobber/billing/components/OnPauseLabel",
  Component: lazyRouteComponent(
    () => import("~/jobber/billing/components/OnPauseLabel/OnPauseLabel"),
    "OnPauseLabel",
  ),
});

registry.registerIsland({
  name: "jobber/usageBasedFreeAccess/views/PostTrialEducationModal",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/usageBasedFreeAccess/views/PostTrialEducationModal/PostTrialEducationModal"
      ),
    "PostTrialEducationModal",
  ),
});

registry.registerIsland({
  name: "jobber/usageBasedFreeAccess/views/LandingPage",
  Component: lazyRouteComponent(
    () => import("~/jobber/usageBasedFreeAccess/views/landingPage/LandingPage"),
    "LandingPage",
  ),
});

registry.registerIsland({
  name: "jobber/clients/components/ClientBalanceFeedbackCard",
  Component: lazyRouteComponent(
    () => import("~/jobber/clients/components/ClientBalanceFeedbackCard"),
    "ClientBalanceFeedbackCard",
  ),
});

registry.registerIsland({
  name: "jobber/features/Marketing/Reviews/views/ReviewRequestToggle",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/jobber/features/Marketing/Reviews/views/ReviewRequestToggle/ReviewRequestToggle"
      ),
    "ReviewRequestToggle",
  ),
});

registry.defineElement();
